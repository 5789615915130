
  export default {
    name: 'ExtrasNavigation',
    data: () => ({
      menuItems: [
        {
          icon: 'fal fa-comment-dots',
          title: 'MEIN POSTEINGANG',
          route: {
            name: 'index',
          },
        },
        {
          icon: 'fal fa-money-bill',
          title: 'Meine Rechnungen',
          route: {
            name: 'index',
          },
        },
        {
          icon: 'fal fa-sticky-note',
          title: 'Meine Notizen',
          route: {
            name: 'index',
          },
        },
        {
          icon: 'fal fa-search',
          title: 'Meine Suchen',
          route: {
            name: 'index',
          },
        },
        {
          icon: 'fal fa-user-friends',
          title: 'Freunde einladen',
          route: {
            name: 'index',
          },
        },
        {
          icon: 'fal fa-share-alt',
          title: 'Teilen',
          route: {
            name: 'index',
          },
        },
      ],
    }),
  };
